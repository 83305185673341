/* =========
  Dark Mode For Squarespace
========== */

.dark-mode-on {
 --active-button: white;
  
}

 .dark-mode-on .dark-button svg path{
  fill: var(--active-button);
}

.dark-button.floating {
  height: 35px;
  width: 35px;
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  display: block;
  z-index: 10;
}

.dark-button {
    cursor: pointer;
}

  #page:has(.system-page) {
    flex: 1;
    display:flex;
    width:100%;
    background: var(--siteBackgroundColor);
  }
  .system-page{
    width: 100%;
    align-items: start;
  }

